@import "~@/styles/variables";























































































.meta {
  flex-grow: 1;
  font-size: 1.2em;
  color: $pp-ocean;
}
